import styled from "styled-components";

export const CircularButton = styled.button`
	width: 40vw; /* 50% of viewport width */
	max-width: 200px; /* Maximum width of 300px */
	height: calc(40vw); /* Same as width */
	max-height: 200px; /* Maximum height of 300px */
	border-radius: 50%; /* Makes the button circular */
	background-color: #8bc24a;
	border: none;
	color: white;
	font-size: 3rem;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const HomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`;

export const HomeText = styled.h1`
	font-size: 48px;
	margin-bottom: 0px;
`;

export const HomeSubText = styled.h1`
	font-size: 48px;
	margin-top: 8px;
	margin-bottom: 20px;
`;
