import LocationSpot from "../data/LocationInterface";
import AreaLocation from "../data/stamp-rally/AreaLocationInterface";

// Define a function to get the user's location with error handling
export async function trackLocation(): Promise<{
	latitude: number;
	longitude: number;
} | null> {
	try {
		const position = await new Promise<GeolocationPosition>((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject, {
				enableHighAccuracy: true,
				maximumAge: 0,
			});
		});

		const { latitude, longitude } = position.coords;
		return { latitude, longitude };
	} catch (error) {
		console.error("Failed to get user's location:", error);
		return null;
	}
}

// Define a function to check if the user is within radius of any location
export function isWithinRadius(
	userLocation: { latitude: number; longitude: number },
	locations: LocationSpot[]
): LocationSpot | null {
	const earthRadius = 6371000; // Earth's radius in meters (mean radius)

	for (const location of locations) {
		const { latitude: lat1, longitude: lon1 } = userLocation;
		const { latitude: lat2, longitude: lon2, radius } = location;

		const dLat = ((lat2 - lat1) * Math.PI) / 180;
		const dLon = ((lon2 - lon1) * Math.PI) / 180;

		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos((lat1 * Math.PI) / 180) *
				Math.cos((lat2 * Math.PI) / 180) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = earthRadius * c;

		if (distance <= radius) {
			return location; // User is within the radius of this location
		}
	}

	return null; // User is not within radius of any location
}

export function isWithinRadiusStampRally(
	userLocation: { latitude: number; longitude: number },
	locations: AreaLocation[]
): AreaLocation | null {
	const earthRadius = 6371000; // Earth's radius in meters (mean radius)

	for (const location of locations) {
		const { latitude: lat1, longitude: lon1 } = userLocation;
		const { latitude: lat2, longitude: lon2, radius } = location;

		const dLat = ((lat2 - lat1) * Math.PI) / 180;
		const dLon = ((lon2 - lon1) * Math.PI) / 180;

		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos((lat1 * Math.PI) / 180) *
				Math.cos((lat2 * Math.PI) / 180) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = earthRadius * c;

		if (distance <= radius) {
			return location; // User is within the radius of this location
		}
	}

	return null; // User is not within radius of any location
}
