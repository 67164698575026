import axios, { AxiosResponse } from "axios";
import { DrawApiResponse, DrawRequestApiResponse } from "../../data/stamp-rally/DrawApiResponse";
import { Draw, CheckDraw } from "../../data/stamp-rally/DrawInterface";

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const apiConfig = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json",
		"x-api-key": apiKey,
	},
});

export async function executeDraw(userId: string): Promise<Draw> {
	try {
		const response: AxiosResponse<DrawRequestApiResponse> = await apiConfig.post(
			"/takasaki-university/v1/area-gps-stamp-rally/draw",
			{ user_id: userId }
		);
		if (response.data.statusCode === 1000) {
			const draw: Draw = response.data.draw;
			return draw;
		} else {
			throw new Error("Failed to execute draw");
		}
	} catch (error) {
		console.log(error);
		throw new Error("Failed to execute draw");
	}
}

export async function checkDraw(userId: string): Promise<CheckDraw> {
	try {
		const response: AxiosResponse<DrawApiResponse> = await apiConfig.get(
			"/takasaki-university/v1/area-gps-stamp-rally/draw/" + userId
		);
		if (response.data.statusCode === 1000) {
			const checkDraw: CheckDraw = {
				already_drawn: response.data.already_drawn,
				draw: response.data.draw,
			};
			return checkDraw;
		} else {
			throw new Error("Failed to parse user completed areas from the API response");
		}
	} catch (error) {
		throw new Error("Failed to fetch user completed areas");
	}
}
