import styled from "styled-components";

export const LocationDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100vh;
`;

export const ImageContainer = styled.div`
	width: 100%;
	height: 50vh;
	background-image: url("your-image-url.jpg"); /* Replace with your image URL */
	background-size: cover;
	background-position: center;
`;

export const Title = styled.div`
	padding: 20px;
	margin-top: 16px;
	font-size: 64px;
	font-weight: bold;
	text-align: center;
`;

export const Description = styled.div`
	padding: 20px;
	margin-top: 16px;
	font-size: 32px;
	font-weight: bold;
	text-align: center;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1; /* Allow this container to grow and take up the remaining space */
	justify-content: space-evenly;
`;
