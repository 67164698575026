import "@fontsource/inter";
import {
	CircleWithLine,
	ButtonContainer,
	HomeContainer,
	ItemContainer,
	LoadingContent,
	LoadingOverlay,
	RowContainer,
	Wrapper,
	ButtonLoadingIndicator,
} from "./AreasStyles"; // Import your styled components
import { useEffect, useState } from "react";
import { getAreaLocations } from "../../../services/stamp-rally/areaLocationsService";
import AreaLocation from "../../../data/stamp-rally/AreaLocationInterface";
import { getUserCompletedAreas } from "../../../services/stamp-rally/userService";
import Button from "@mui/joy/Button";
import { checkDraw, executeDraw } from "../../../services/stamp-rally/drawService";
import { useNavigate } from "react-router-dom";
import { isWithinRadiusStampRally, trackLocation } from "../../../utils/LocationUtils";

function Areas() {
	const [areaLocations, setAreaLocations] = useState<AreaLocation[]>([]);
	const [location, setLocation] = useState<{
		latitude: number;
		longitude: number;
	} | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [loadingButton, setLoadingButton] = useState(false);
	const [hasCompletedLocation, setHasCompletedLocation] = useState<boolean>(false);
	const [alreadyDrawn, setAlreadyDrawn] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	const getLocation = async () => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(async function (result) {
				if (result.state === "granted") {
					const position = await trackLocation();
					setLocation(position);
				} else if (result.state === "prompt") {
					const position = await trackLocation();
					setLocation(position);
				} else if (result.state === "denied") {
					setError("Permission denied. Please enable location access in your browser settings.");
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			setError("Location data not available in this browser");
		}
	};

	const finishStampRally = async () => {
		const userId = localStorage.getItem("stampRallyUserId");
		if (!userId) {
			setError("Invalid userId");
			return;
		}
		setLoadingButton(true);
		const drawData = { numberOfDraws: 0, points: 0 };
		try {
			const result = await checkDraw(userId);
			console.log(result);
			if (result.already_drawn) {
				drawData.numberOfDraws = result.draw.number_of_draws;
				drawData.points = result.draw.total_points;
				return navigate(`/stamp-rally/result-draw`, { state: drawData });
			}
			if (hasCompletedLocation) {
				const drawResult = await executeDraw(userId);
				console.log(drawResult);
				drawData.numberOfDraws = drawResult.number_of_draws;
				drawData.points = drawResult.total_points;
				return navigate(`/stamp-rally/result-draw`, { state: drawData });
			}
		} catch (error: any) {
			setLoadingButton(false);
			switch (error.message) {
				case "USER_NOT_EXISTS_ERRORMESSAGE":
					setError("User not found.");
					break;
				case "ALREADY_DRAWN_ERRORMESSAGE":
					setError("Failed to execute draw.");
					break;
				default:
					setError("An error occurred. Please try again later.");
			}
		} finally {
			setLoadingButton(false);
		}
	};

	useEffect(() => {
		getLocation();
		const intervalId = setInterval(() => {
			getLocation();
		}, 2000); // 2 seconds
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		async function fetchData() {
			try {
				setIsLoading(true);
				const userId = localStorage.getItem("stampRallyUserId");
				if (!userId || userId === null) {
					navigate(`/stamp-rally/email-registration`);
				}
				// Check if the user has already drawn
				const userAlreadyDrawn = await checkDraw(userId!);
				setAlreadyDrawn(userAlreadyDrawn.already_drawn);
				// Fetch area locations
				const areas = await getAreaLocations();
				// Fetch user completed areas
				const userCompletedAreas = await getUserCompletedAreas(userId!);
				const updatedAreas = areas.map((area, index) => {
					const completedArea = userCompletedAreas.find(
						(completed) => completed.area_location_id === area.area_location_id
					);
					return { ...area, completed: !!completedArea };
				});
				// Update the state of areaLocations
				setAreaLocations(updatedAreas);
				// Check if the user has completed any location
				setHasCompletedLocation(userCompletedAreas.length > 0);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setIsLoading(false);
			}
		}
		if (areaLocations.length === 0 && alreadyDrawn === false && hasCompletedLocation === false) {
			fetchData();
		}
	}, [areaLocations, alreadyDrawn, hasCompletedLocation, navigate]);

	useEffect(() => {
		const areaLocation = isWithinRadiusStampRally(
			{ latitude: location?.latitude!, longitude: location?.longitude! },
			areaLocations
		);
		if (areaLocation) {
			console.log("Found " + areaLocation.completed);
			var dataLocation = { area: areaLocation };
			navigate(`/stamp-rally/areas/${areaLocation.area_location_id}`, { state: dataLocation }); // Found location, navigate to the details view
		}
	}, [location, areaLocations, navigate]);

	useEffect(() => {
		if (error) {
			console.log(error);
		}
	}, [error]);

	useEffect(() => {
		const areaLocation = isWithinRadiusStampRally(
			{ latitude: location?.latitude!, longitude: location?.longitude! },
			areaLocations
		);
		if (areaLocation) {
			console.log("Found " + areaLocation.completed);
			var dataLocation = { area: areaLocation };
			navigate(`/stamp-rally/areas/${areaLocation.area_location_id}`, {
				state: dataLocation,
			}); // Found location, navigate to the details view
		}
	}, [location, areaLocations, navigate]);

	useEffect(() => {
		if (error) {
			console.log(error);
		}
	}, [error]);

	return (
		<HomeContainer>
			{isLoading ? (
				<LoadingOverlay>
					<LoadingContent>
						<div className="spinner-border" role="status">
							<span className="visually-hidden">読み込み中</span>
						</div>
					</LoadingContent>
				</LoadingOverlay>
			) : (
				<div style={{ width: "100%" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						{areaLocations.map((areaLocation) => (
							<RowContainer key={areaLocation.area_location_id}>
								<Wrapper
									style={{
										flex: 0.2,
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{/* <Circle data={ { completed: areaLocation.completed } }/> */}
									<CircleWithLine
										data={{ completed: areaLocation.completed, isLast: areaLocation.isLast }}
									/>
								</Wrapper>
								<ItemContainer style={{ flex: 0.8 }}>
									<div>{areaLocation.area_name}</div>
								</ItemContainer>
							</RowContainer>
						))}
					</div>
					<ButtonContainer>
						{loadingButton ? (
							<ButtonLoadingIndicator /> // Render loading indicator while registering
						) : (
							<Button
								sx={{
									color: "#FFFFFF",
									backgroundColor: "#FF6666",
									fontSize: "24px",
									width: "100%",
									paddingTop: "16px",
									paddingBottom: "16px",
									borderRadius: "64px",
								}}
								size="lg"
								variant="solid"
								disabled={hasCompletedLocation && !alreadyDrawn ? false : true}
								onClick={finishStampRally}
							>
								終了
							</Button>
						)}
					</ButtonContainer>
				</div>
			)}
		</HomeContainer>
	);
}

export default Areas;
