import "@fontsource/inter";
import { useNavigate } from "react-router-dom";
import { HomeContainer, HomeText, HomeSubText, CircularButton } from "./StampRallyStyles"; // Import your styled components

function StampRally() {
	const navigate = useNavigate();

	const openEmailRegistration = () => {
		const userId = localStorage.getItem("stampRallyUserId");
		if (userId) {
			navigate(`/stamp-rally/areas`);
		} else {
			navigate(`/stamp-rally/email-registration`);
		}
	};

	return (
		<HomeContainer>
			<HomeText>GPS機能付き</HomeText>
			<HomeSubText>スタンプラリー</HomeSubText>
			<CircularButton onClick={openEmailRegistration}>GO</CircularButton>
		</HomeContainer>
	);
}

export default StampRally;
