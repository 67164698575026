import styled from "styled-components";

export const HomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`;

export const HomeText = styled.h1`
	font-size: 64px;
	margin-bottom: 0px;
`;

export const HomeSubText = styled.h1`
	font-size: 64px;
	margin-top: 8px;
	margin-bottom: 20px;
`;

export const HomeImage = styled.img`
	max-width: 100%;
	height: auto;
`;
