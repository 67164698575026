import VideoComponent from "../../components/VideoComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonContainer, PlayerWrapper, VideoContainer } from "./PlayVideoStyles";
import IconButton from "@mui/joy/IconButton";
import Home from "@mui/icons-material/Home";

function PlayVideo() {
	const navigate = useNavigate();
	const location = useLocation();

	const handleVideoEnd = () => {
		navigate(-1); // Go back to the details page
	};

	const openHome = () => {
		navigate("/");
	};

	return (
		<VideoContainer>
			<PlayerWrapper>
				<VideoComponent video_url={location.state} onVideoEnd={handleVideoEnd} />
			</PlayerWrapper>
			<ButtonContainer>
				<IconButton variant="plain" onClick={openHome}>
					<Home
						sx={{
							fontSize: "64px",
						}}
						fontSize="inherit"
					/>
				</IconButton>
			</ButtonContainer>
		</VideoContainer>
	);
}

export default PlayVideo;
