import React from "react";
import ReactPlayer from "react-player";

interface VideoProps {
	video_url: string;
	onVideoEnd: () => void;
}

const VideoComponent: React.FC<VideoProps> = ({ video_url, onVideoEnd }) => {
	return <ReactPlayer width="100%" height="100%" url={video_url} playing controls onEnded={onVideoEnd} />;
};

export default VideoComponent;
