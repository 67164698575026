import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/joy/Button";
import IconButton from "@mui/joy/IconButton";
import Home from "@mui/icons-material/Home";
import "@fontsource/inter";
import { LocationDetailsContainer, ImageContainer, Description, ButtonContainer, Title } from "./LocationDetailsStyles";
import LocationSpot from "../../data/LocationInterface";
import { useEffect } from "react";
import catsound from "../../assets/cat.mp3";

function LocationDetails() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationSpot: LocationSpot = location.state;

	const openHome = () => {
		navigate("/");
	};

	const openVideo = () => {
		navigate(`/video/${locationSpot.name}`, { state: locationSpot.video_url });
	};

	useEffect(() => {
		let audio = new Audio(catsound);
		audio.play();
	}, []);

	return (
		<LocationDetailsContainer>
			<ImageContainer style={{ backgroundImage: `url(${locationSpot.image_url})` }} />
			<Title>{locationSpot.name}</Title>
			<Description>{locationSpot.description}</Description>
			<ButtonContainer>
				<Button
					sx={{
						color: "#FFFFFF",
						backgroundColor: "#FF6666",
						fontSize: "64px",
						paddingLeft: "64px",
						paddingRight: "64px",
						paddingTop: "32px",
						paddingBottom: "32px",
						borderRadius: "64px",
					}}
					size="lg"
					variant="solid"
					onClick={openVideo}
				>
					動画を見る
				</Button>
				<IconButton variant="plain" onClick={openHome}>
					<Home
						sx={{
							fontSize: "64px",
						}}
						fontSize="inherit"
					/>
				</IconButton>
			</ButtonContainer>
		</LocationDetailsContainer>
	);
}

export default LocationDetails;
