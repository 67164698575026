import axios, { AxiosResponse } from "axios";
import LocationSpot from "../data/LocationInterface";
import GeoParkApiResponse from "../data/GeoParkApiResponse";

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const apiConfig = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json",
		"x-api-key": apiKey,
	},
});

export async function getLocationSpots(): Promise<LocationSpot[]> {
	try {
		const response: AxiosResponse<GeoParkApiResponse> = await apiConfig.get("/takasaki-university/v1/geo-park");

		if (response.data.statusCode === 1000) {
			// Map the "geo_park_locations" array to LocationSpot objects
			const locationSpots: LocationSpot[] = response.data.geo_park_locations.map((location: any) => ({
				latitude: location.latitude,
				longitude: location.longitude,
				video_url: location.video_url,
				image_url: location.image_url || "",
				name: location.name || "",
				description: location.description || "",
				address: location.address || "",
				radius: location.radius || 100,
			}));
			return locationSpots;
		} else {
			throw new Error("Failed to parse location spots from the API response");
		}
	} catch (error) {
		throw new Error("Failed to fetch location spots");
	}
}
