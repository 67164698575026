import axios, { AxiosResponse } from "axios";
import UserCompletedArea from "../../data/stamp-rally/UserCompletedAreasInterface";
import UserCompletedAreasApiResponse from "../../data/stamp-rally/UserCompletedAreasApiResponse";
import UserApiResponse from "../../data/stamp-rally/UserApiResponse";

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const apiConfig = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json",
		"x-api-key": apiKey,
	},
});

export async function signUp(userEmail: string): Promise<string> {
	try {
		const response: AxiosResponse<UserApiResponse> = await apiConfig.post(
			"/takasaki-university/v1/area-gps-stamp-rally/user",
			{ email: userEmail }
		);
		if (response.data.statusCode === 1000) {
			const userId: string = response.data.user_id;
			return userId;
		} else {
			throw new Error("Failed to register user to the API");
		}
	} catch (error) {
		console.log(error);
		throw new Error("Failed to save user");
	}
}

export async function completeArea(userId: string, areaLocationId: string): Promise<boolean> {
	try {
		const response: AxiosResponse<UserCompletedAreasApiResponse> = await apiConfig.post(
			"/takasaki-university/v1/area-gps-stamp-rally/user-completed-areas",
			{ area_location_id: areaLocationId, user_id: userId }
		);
		if (response.data.statusCode === 1000) {
			return true;
		} else {
			throw new Error("Failed to register completed area to the API");
		}
	} catch (error) {
		console.log(error);
		throw new Error("Failed to save completed area");
	}
}

export async function getUserCompletedAreas(userId: string): Promise<UserCompletedArea[]> {
	try {
		const response: AxiosResponse<UserCompletedAreasApiResponse> = await apiConfig.get(
			"/takasaki-university/v1/area-gps-stamp-rally/user-completed-areas/" + userId
		);
		if (response.data.statusCode === 1000) {
			// Map the "geo_park_locations" array to LocationSpot objects
			const userCompletedAreas: UserCompletedArea[] = response.data.user_completed_areas.map((area: any) => ({
				user_completed_area_id: area.user_completed_area_id,
				area_location_id: area.area_location_id,
				user_id: area.user_id,
				created_at: area.created_at,
				updated_at: area.updated_at,
			}));
			return userCompletedAreas;
		} else {
			throw new Error("Failed to parse user completed areas from the API response");
		}
	} catch (error) {
		throw new Error("Failed to fetch user completed areas");
	}
}
