import styled from "styled-components";

export const VideoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100vh;
`;

export const PlayerWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 50vh;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1; /* Allow this container to grow and take up the remaining space */
	justify-content: center;
`;
