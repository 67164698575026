import { useLocation, useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/joy/IconButton";
import Home from "@mui/icons-material/Home";
import "@fontsource/inter";
import {
	LocationDetailsContainer,
	ImageContainer,
	Description,
	ButtonContainer,
	Title,
	PlayerWrapper,
} from "./AreaDetailsStyles";
import { useEffect, useState } from "react";
import { getAreaLocation } from "../../../services/stamp-rally/areaLocationsService";
import { completeArea } from "../../../services/stamp-rally/userService";
import AreaLocation from "../../../data/stamp-rally/AreaLocationInterface";
import VideoComponent from "../../../components/VideoComponent";

function AreaDetails() {
	const [areaLocation, setAreaLocation] = useState<AreaLocation>();
	const { area_id } = useParams<{ area_id: string }>();
	const navigate = useNavigate();
	const location = useLocation();
	const receivedData = location.state;

	const moveBack = () => {
		navigate(`/stamp-rally/areas`); // go back to the areas page
	};

	useEffect(() => {
		async function fetchData() {
			try {
				var area: AreaLocation = receivedData.area;
				if (!area) {
					area = await getAreaLocation(area_id ?? "");
				}
				setAreaLocation(area);
				const userId = localStorage.getItem("stampRallyUserId");
				if (userId) {
					if (!area.completed) {
						await completeArea(userId, area.area_location_id);
					}
				} else {
					navigate(`/stamp-rally/email-registration`);
				}
			} catch (err: any) {
				setError(err.message);
			}
		}
		fetchData();
	}, [receivedData.area, area_id, navigate]);

	const handleVideoEnd = () => {};

	return (
		<LocationDetailsContainer>
			<Title>{areaLocation?.area_name}</Title>
			<Description>{areaLocation?.description}</Description>
			{areaLocation?.image_url === "" ? (
				""
			) : (
				<ImageContainer
					style={{
						height: `${areaLocation?.video_url !== "" ? "30vh" : "50vh"}`,
						backgroundImage: `url(${areaLocation ? areaLocation.image_url : ""})`,
					}}
				></ImageContainer>
			)}
			{areaLocation?.video_url === "" ? (
				""
			) : (
				<PlayerWrapper
					style={{
						height: `${areaLocation?.image_url !== "" ? "30vh" : "50vh"}`,
					}}
				>
					<VideoComponent video_url={areaLocation?.video_url ?? ""} onVideoEnd={handleVideoEnd} />
				</PlayerWrapper>
			)}
			<ButtonContainer>
				<IconButton variant="plain" onClick={moveBack}>
					<Home
						sx={{
							fontSize: "64px",
						}}
						fontSize="inherit"
					/>
				</IconButton>
			</ButtonContainer>
		</LocationDetailsContainer>
	);
}

export default AreaDetails;
function setError(message: any) {
	throw new Error("Function not implemented.");
}
