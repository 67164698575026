import "@fontsource/inter";
import { useNavigate } from "react-router-dom";
import homeImage from "../../assets/home.png";
import { HomeContainer, HomeText, HomeSubText, HomeImage } from "./HomeStyles"; // Import your styled components
import { useEffect, useState } from "react";
import { trackLocation, isWithinRadius } from "../../utils/LocationUtils";
import LocationSpot from "../../data/LocationInterface";
import { getLocationSpots } from "../../services/locationService";

function Home() {
	const [locationSpots, setLocationSpots] = useState<LocationSpot[]>([]);
	const [location, setLocation] = useState<{
		latitude: number;
		longitude: number;
	} | null>(null);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	const getLocation = async () => {
		if (navigator.geolocation) {
			navigator.permissions.query({ name: "geolocation" }).then(async function (result) {
				if (result.state === "granted") {
					const position = await trackLocation();
					setLocation(position);
				} else if (result.state === "prompt") {
					const position = await trackLocation();
					setLocation(position);
				} else if (result.state === "denied") {
					setError("Permission denied. Please enable location access in your browser settings.");
				}
				result.onchange = function () {
					console.log(result.state);
				};
			});
		} else {
			setError("Location data not available in this browser");
		}
	};

	useEffect(() => {
		async function fetchData() {
			try {
				const spots = await getLocationSpots();
				setLocationSpots(spots);
			} catch (err: any) {
				setError(err.message);
			}
		}

		fetchData();
	}, []);

	useEffect(() => {
		console.log(error);
	}, [error]);

	useEffect(() => {
		getLocation();

		// Set up an interval to get location every 30 seconds
		const intervalId = setInterval(() => {
			getLocation();
		}, 10000); // 10 seconds

		// Clean up the interval when the component unmounts
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		console.log(location?.latitude);
		console.log(location?.longitude);
		const locationSpot = isWithinRadius(
			{ latitude: location?.latitude!, longitude: location?.longitude! },
			locationSpots
		);
		if (locationSpot) {
			navigate(`/location/${locationSpot.name}`, { state: locationSpot }); // Found location, navigate to the details view
		}
	}, [location, locationSpots, navigate]);

	return (
		<HomeContainer>
			<HomeText>下仁田</HomeText>
			<HomeSubText>ジオパーク</HomeSubText>
			<HomeImage src={homeImage} alt="下仁田ジオパーク Image" className="home-image" />
		</HomeContainer>
	);
}

export default Home;
