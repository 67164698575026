import "@fontsource/inter";
import { HomeContainer, HomeText, HomeSubText } from "./ResultDrawStyles"; // Import your styled components
import { useLocation } from "react-router-dom";

function ResultDraw() {
	const location = useLocation();
	const receivedData = location.state;

	return (
		<HomeContainer>
			<HomeSubText>獲得ポイント</HomeSubText>
			<HomeText>{receivedData.points} Pt</HomeText>
			<HomeSubText>抽選回数</HomeSubText>
			<HomeText>{receivedData.numberOfDraws} 回</HomeText>
		</HomeContainer>
	);
}

export default ResultDraw;
