import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./views/home/Home";
import LocationDetails from "./views/location-details/LocationDetails";
import PlayVideo from "./views/video/PlayVideo";
import StampRally from "./views/stamp-rally/StampRally";
import EmailRegistration from "./views/stamp-rally/email-registration/EmailRegistration";
import ResultDraw from "./views/stamp-rally/result-draw/ResultDraw";
import AreaDetails from "./views/stamp-rally/area-details/AreaDetails";
import Areas from "./views/stamp-rally/areas/Areas";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/video/:id" element={<PlayVideo />} />
        <Route path="/location/:id" element={<LocationDetails />} />
        <Route path="/stamp-rally/" element={<StampRally />} />
        <Route path="/stamp-rally/email-registration" element={<EmailRegistration />} />
        <Route path="/stamp-rally/areas" element={<Areas />} />
        <Route path="/stamp-rally/areas/:area_id" element={<AreaDetails />} />
        <Route path="/stamp-rally/result-draw" element={<ResultDraw />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
