import axios, { AxiosResponse } from "axios";
import AreaLocation from "../../data/stamp-rally/AreaLocationInterface";
import AreaLocationApiResponse from "../../data/stamp-rally/AreaLocationApiResponse";
import AreaLocationsApiResponse from "../../data/stamp-rally/AreaLocationApiResponse";

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const apiConfig = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json",
		"x-api-key": apiKey,
	},
});

export async function getAreaLocations(): Promise<AreaLocation[]> {
	try {
		const response: AxiosResponse<AreaLocationsApiResponse> = await apiConfig.get(
			"/takasaki-university/v1/area-gps-stamp-rally/area-locations"
		);
		if (response.data.statusCode === 1000) {
			// Map the "geo_park_locations" array to LocationSpot objects
			const areaLocations: AreaLocation[] = response.data.area_locations.map((location: any, index) => {
				const isLastItem = index === response.data.area_locations.length - 1; // Check if current index is the last index
				const areaLocation: AreaLocation = {
					area_location_id: location.area_location_id,
					area_name: location.area_name,
					description: location.description,
					image_url: location.image_url,
					video_url: location.video_url,
					latitude: location.latitude,
					longitude: location.longitude,
					radius: location.radius,
					points: location.points,
					created_at: location.created_at,
					updated_at: location.updated_at,
					delete_flag: location.delete_flag,
					completed: false,
					isLast: isLastItem,
				};
				return areaLocation;
			});
			return areaLocations;
		} else {
			throw new Error("Failed to parse area locations from the API response");
		}
	} catch (error) {
		throw new Error("Failed to fetch area locations");
	}
}

export async function getAreaLocation(areaLocationId: string): Promise<AreaLocation> {
	try {
		const response: AxiosResponse<AreaLocationApiResponse> = await apiConfig.get(
			"/takasaki-university/v1/area-gps-stamp-rally/area-locations/" + areaLocationId
		);
		if (response.data.statusCode === 1000) {
			const areaLocation: AreaLocation = response.data.area_location;
			return areaLocation;
		} else {
			throw new Error("Failed to parse area locations from the API response");
		}
	} catch (error) {
		throw new Error("Failed to fetch area locations");
	}
}
