import "@fontsource/inter";
import { useNavigate } from "react-router-dom";
import {
	HomeContainer,
	HomeText,
	HomeSubText,
	TextInput,
	ButtonContainer,
	ErrorMessage,
	SuccessMessage,
	LoadingIndicator,
} from "./EmailRegistrationStyles"; // Import your styled components
import { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import { signUp } from "../../../services/stamp-rally/userService";

function EmailRegistration() {
	const [email, setEmail] = useState<string>("");
	const [error, setError] = useState<string | null>(null);
	const [isSuccess, setIsSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const registerEmail = async () => {
		if (!validateEmail(email)) {
			setError("Invalid email format");
			return;
		}

		try {
			setError(null); // Clear any previous errors before signup
			setLoading(true); // Set loading state to true
			const userId = await signUp(email);
			setIsSuccess(true);
			localStorage.setItem("stampRallyUserId", userId);
			navigate(`/stamp-rally/areas`);
		} catch (error: any) {
			setLoading(false); // Set loading state to false after error
			switch (error.message) {
				case "Email already exists":
					setError("Email already exists. Please try a different email.");
					break;
				default:
					setError("An error occurred. Please try again later.");
			}
		} finally {
			setLoading(false); // Ensure loading state is set to false regardless of success or error
		}
	};

	function validateEmail(email: string): boolean {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	useEffect(() => {
		if (error) {
			console.log(error);
		}
	}, [error]);

	return (
		<HomeContainer>
			<HomeText>メールアドレス</HomeText>
			<HomeSubText>登録</HomeSubText>
			<TextInput type="email" value={email} onChange={(event) => setEmail(event.target.value)}></TextInput>
			<ButtonContainer>
				{loading ? (
					<LoadingIndicator /> // Render loading indicator while registering
				) : (
					<Button
						sx={{
							color: "#FFFFFF",
							backgroundColor: "#FF6666",
							fontSize: "24px",
							width: "100%",
							paddingTop: "16px",
							paddingBottom: "16px",
							borderRadius: "64px",
						}}
						size="lg"
						variant="solid"
						onClick={registerEmail}
					>
						登録
					</Button>
				)}
			</ButtonContainer>
			{error && <ErrorMessage>{error}</ErrorMessage>}{" "}
			{isSuccess && <SuccessMessage>Your email has been registered!</SuccessMessage>}
		</HomeContainer>
	);
}

export default EmailRegistration;
