import styled from "styled-components";

export const LoadingOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 999;
`;

export const LoadingContent = styled.div`
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const RowContainer = styled.div`
	display: flex;
	align-items: center; /* Maintain vertical centering */
	justify-content: flex-start; /* Add this property */
	margin: 15px;
`;

export const ItemContainer = styled.div`
	display: flex;
	align-items: center; /* Maintain vertical centering */
	background-color: #8bc24a;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 10px;
	font-size: 24px;
	color: white;
	position: relative;
	justify-content: center;
	margin-right: 15px;
`;

export const Wrapper = styled.div`
	width: auto;
	height: auto;
	margin-right: 10px;
	margin-left: 10px;
`;

export const ButtonLoadingIndicator = styled.div`
	width: 32px;
	height: 32px;
	border: 4px solid #ccc;
	border-radius: 50%;
	border-top-color: #f00; /* Red color for spinning animation */
	animation: spin 1s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1; /* Allow this container to grow and take up the remaining space */
	justify-content: center;
	margin-bottom: 40px;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 30px;
`;

export const HomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	margin-top: 20px;
`;

interface CircleProps {
	data: { completed: boolean; isLast?: boolean };
}

export const CircleWithLine = styled.div<CircleProps>`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid #ff6666;
	position: relative;
	background-color: ${(props) => (props.data?.completed ? "#ff6666" : "white")};

	${(props) =>
		props.data?.isLast
			? ""
			: `&::after {
		content: "";
		position: absolute;
		right: 50%;
		top: 0;
		transform: translateY(75%);
		width: 2px;
		height: 135%;
		border: none;
		background-color: #ff6666;
	}`}
`;
