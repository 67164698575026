import styled from "styled-components";

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1; /* Allow this container to grow and take up the remaining space */
	justify-content: center;
`;

export const TextInput = styled.input`
	width: 300px; /* Take up full width of the container */
	height: 40px; /* Adjust height as needed */
	border: 2px solid black; /* Black border */
	border-radius: 5px; /* Rounded corners */
	padding: 8px 12px; /* Padding inside the input field */
	font-size: 1rem; /* Adjust font size as needed */
	box-sizing: border-box; /* Include border and padding in the total width */
	margin-top: 40px; /* Add margin top */
`;

export const HomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`;

export const HomeText = styled.h1`
	font-size: 64px;
`;

export const HomeSubText = styled.div`
	font-size: 48px;
	margin-top: 8px;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
	font-weight: bold;
`;
